<template>
  <div>
    <div class="text-center" style="height: 90vh">
      登入中...
    </div>
  </div>
</template>
<script>
import { getMember, updFirstPopup } from '@/api/user'
import { getStoreCode, showFirstLoginConfirm, showNotice } from '@/utils/tool'
import { setupRefreshToken } from '@/utils/user'
export default {
  name: 'SSOaccept',
  data() {
    return {
      isdo: '',
      token: '',
      opappuserconfirmtext: {
        message:
          '請同意將您的 OPEN錢包會員帳號提供給三禘券鏈通科技股份有限公司。\n為維護您的權益，請詳閱注意事項並在暸解後，點選同意：',
        submessage:
          '1.OPEN 錢包僅會在取得您的同意後，將您的指定個人資料提供給三禘券鏈通科技股份有限公司。\n' +
          '2.商城內之相關個人資料蒐集﹑處理與利用均由三禘券鏈通科技股份有限公司負責管理，本公司不對商城內之服務或三禘券鏈通科技股份有限公司負任何擔保或責任，若有相關問題請洽詢三禘券鏈通科技股份有限公司。\n' +
          '3.當您點擊下方「同意進入」，代表您同意提供上述指定個人資料給三禘券鏈通科技股份有限公司。',
        submessage2: '您即將進入由三禘券鏈通科技股份有限公司提供的服務。'
      }
    }
  },
  created() {
    // icashpay 和 openpoint 的登入頁
    // openpoint -> use = opapp
    this.$store.commit('SET_LOADING', true)
    const host = new URL(window.location.href || document.location.href)
    try {
      const isuse = this.$route.query.use
      const ver = this.$route.query.ver
      const tourl = this.$route.query.to
      // icashpay 過來會有轉換問題透過decodeURI轉換
      const token = Buffer.from(
        decodeURI(host.searchParams.get('token')),
        'base64'
      ).toString()
      const isfirstpopup = this.$route.query.is_first_popup
      // do login
      this.doLoginflow(token, isuse, ver, isfirstpopup, tourl)
    } catch (error) {
      this.$store.commit('SET_LOADING', false)
      showNotice('請關閉頁面,重新透過APP再進入商城。', function() {
        this.$router.push({ path: '/index' })
      })
    }
  },
  destroyed() {
    this.$store.commit('SET_LOADING', false)
  },
  methods: {
    doLoginflow(token, useapp, version, isfirstpopup, tourl) {
      const self = this
      this.$store.commit('SET_IN_APP', { name: useapp, ver: version })
      this.$store.commit('SET_AUTH_INFO', token)
      // open point
      if (useapp && useapp === 'opapp') {
        this.$store.commit('SET_LOGIN_TYPE', 'opapp')
        // 關閉登出按鈕
        this.$store.commit('SET_LOGOUT_AUTH', false)
        // 打開返回按鈕
        this.$store.commit('SET_SHOW_BACKBTN', true)
        // 第一次登入彈出視窗
        if (token && isfirstpopup === 't') {
          showFirstLoginConfirm(
            self.opappuserconfirmtext.message,
            self.opappuserconfirmtext.submessage,
            self.opappuserconfirmtext.submessage2,
            async function() {
              // 確認進去 call api
              await updFirstPopup()
              // 登入
              self.dologin(token, tourl)
            },
            function() {
              // 不要進去關WEBVIEW
              self.$store.commit('SET_LOADING', false)
              window.OPAPPJsInterface.call('OPCloseWebview', null)
            }
          )
        } else if (token && isfirstpopup !== 't') {
          self.dologin(token, tourl)
        } else {
          window.OPAPPJsInterface.call('OPCloseWebview', null)
        }
      } else {
        this.$store.commit('SET_IN_APP', { name: 'icash', ver: '1.0.0' })
        // icash pay
        this.$store.commit('SET_LOGIN_TYPE', getStoreCode())
        // 關閉登出按鈕
        this.$store.commit('SET_LOGOUT_AUTH', false)
        // 關閉返回按鈕
        this.$store.commit('SET_SHOW_BACKBTN', false)
        if (token) {
          self.dologin(token, tourl)
        } else {
          self.$store.commit('SET_LOADING', false)
          window.iCashJSContext.call('CloseWeb')
        }
      }
    },
    dologin(token, tourl) {
      // 登入後取得資料
      getMember()
        .then(response => {
          if (response.data.data.member_id !== undefined) {
            this.$store.commit('SET_MEMBER_INFO', response.data.data)
            setupRefreshToken()
            this.$store.commit('SET_LOADING', false)
            if (tourl) {
              window.location.href = tourl
            } else this.$router.push({ path: '/index' })
          }
        })
        .finally(() => {
          this.$router.push({ path: '/index' })
          this.$store.commit('SET_LOADING', false)
        })
    }
  }
}
</script>
